import React from 'react';

const crowFormHost = process.env.REACT_APP_CROW_FORM_HOST;

class CrowFormEntity extends React.Component {
	constructor(props) {
		super(props);
	}

	renderLabel(entity) {
		let label = (entity.attr || {}).label || entity.name;
		return label;
	}

	renderEntity(entity) {
		const {
			form,
			getValue,
			setValue,
			getStatus,
			setStatus,
		} = this.props;

		if (typeof form.props.customEntity === 'function') {
			entity = form.props.customEntity(entity);
		}

		if (entity.children) {
			let children = entity.children.map((childEntity) => {
				childEntity.parent = entity;

				return childEntity;
			});

			entity.children = children;
		}

		let entityProps = {
			form,
			entity,
			getValue,
			setValue,
			getStatus,
			setStatus,
		}

		let entityType = entity.type.toLowerCase();
		let attr = entity.attr || {};

		let elm = (
			<CrowFormEntity {...entityProps} />
		)

		if (entityType === 'field') {
			elm = (
				<CrowFormEntityField {...entityProps} />
			)
		}

		if (typeof form.props.renderEntity === 'function') {
			return form.props.renderEntity(entity, elm);
		}

		let class_list = attr.class_list || [];
		let customClassList = '';
		class_list.forEach((item) => {
			customClassList += ` ${[item]}`;
		});

		if (entityType === 'form') {
			return (
				<div className={`crow-form${customClassList}`}>
					{elm}
				</div>
			);
		}

		if (entityType === 'page') {
			return (
				<div className={`crow-page${customClassList}`}>
					<h1>{this.renderLabel(entity)}</h1>
					{elm}
				</div>
			);
		}

		if (entityType === 'group') {
			return (
				<div className={`crow-group${customClassList}`}>
					<h2>{this.renderLabel(entity)}</h2>
					{elm}
				</div>
			);
		}

		if (entityType === 'field') {
			return (
				<div className={`crow-field${customClassList}`}>
					<label>{this.renderLabel(entity)}</label>
					{elm}
				</div>
			);
		}

		return (
			<div className={`${entity.type}-entity`}>{elm}</div>
		);
	}

	render() {
		const {
			entity,
		} = this.props;

		if (entity.skip) {
			return '';
		}

		// entity.parent = this;

		if (entity.type !== 'FIELD') {
			if ((entity.attr || {}).fieldType) {
				entity.orgType = entity.type;
				entity.type = 'FIELD';

				entity.fieldType = entity.attr.fieldType;
			}
		}

		let parentEntityName = 'root';
		if (entity.parent) {
			parentEntityName = entity.parent.name;
		}

		if (entity.type !== 'FIELD') {
			return (
				<React.Fragment>
					{entity.children.map((childEntity) => {
						let attr = childEntity.attr || {};

						return (
							<React.Fragment
								key={`${parentEntityName}_${entity.name}_${childEntity.name}`}
							>
								{this.renderEntity(childEntity)}
							</React.Fragment>
						)
					})}
				</React.Fragment>
			)
		}

		return (
			<React.Fragment
				key={`${parentEntityName}_${entity.name}_${entity.name}`}
			>
				{this.renderEntity(entity)}
			</React.Fragment>
		)
	}
}

function injectHead(fieldType, on = {}) {
	if (!window.CrowFormField) {
		window.CrowFormField = {};
	}

	let script = document.createElement('script');
	script.type = 'module';
	script.src = `${crowFormHost}/field/${fieldType}`; // ?update=1

	script.onload = on.load;
	script.onerror = on.error;

	let scripts = document.scripts;
	for (let i = 0, l = scripts.length; i < l; i++) {
		if (scripts[i].src === script.src) {
			return;
		}
	}

	document.head.appendChild(script);
}

class CrowFormEntityField extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fieldInjected: false,
			fieldReady: false,
		};
	}

	componentDidMount() {
		const {
			entity,
		} = this.props;

		// console.log('MOUNT:', entity.name);
		if (window.CrowFormField) {
			if (window.CrowFormField[entity.fieldType]) {
				this.setState({
					fieldReady: true,
				});

				return;
			}
		}

		this.injectHead(entity.fieldType, {
			load: (e) => {
				console.log('LOAD:', e.target.src);
			},

			error: (e) => {
				console.error(e);
			},
		});
	}

	injectHead(fieldType, on = {}) {
		if (!this.state.fieldInjected) {
			injectHead(fieldType, on);

			this.setState({
				fieldInjected: true,
			});
		}

		let t = window.setTimeout(() => {
			if (!window.CrowFormField || !window.CrowFormField[fieldType]) {
				return this.injectHead(fieldType, on)
			}

			this.setState({
				fieldReady: true,
			});
		}, 1000);
	}

	getValue = () => {
		const {
			entity,
		} = this.props;

		if (entity.children) {
			let data = {};
			entity.children.forEach((childEntity) => {
				data[childEntity.name] = Object.assign({}, childEntity, {
					value: this.props.getValue(childEntity.name),
				});
			});

			return data;
		}

		return this.props.getValue(entity.name);
	}

	setValue = (value) => {
		const {
			entity,
		} = this.props;

		if (typeof value === 'object') {
			try {
				let encoded = btoa(JSON.stringify(value));
				value = `json:${encoded}`;
			} catch (err) { }
		}

		this.props.setValue(entity.name, value);
	}

	clearValue = () => {
		const {
			entity,
		} = this.props;

		if (entity.nullable) {
			this.props.setValue(entity.name, null);
		}
	}

	getStatus = () => {
		const {
			entity,
		} = this.props;

		return this.props.getStatus(entity.name);
	}

	setStatus = (status, ...msg) => {
		const {
			entity,
		} = this.props;

		return this.props.setStatus(entity.name, status, ...msg);
	}

	render() {
		const {
			form,
			entity,
		} = this.props;

		if (!entity.attr) {
			entity.attr = {};
		}

		if (!this.state.fieldReady) {
			return (
				<div>{entity.name} - {entity.fieldType}</div>
			)
		}

		let dynamicField = window.CrowFormField[entity.fieldType];
		if (!dynamicField) {
			return 'no_matching_field';
		}
		let Field = dynamicField || 'text.small';

		if (entity.nullable) {
			let nullClassName = "";
			if (this.getValue() === null) {
				nullClassName = "is-null";
			}

			return (
				<div className={`nullable-wrapper ${nullClassName}`}
					title="SHIFT+CLICK to set me to null"
					onClick={(e) => {
						if (e.shiftKey) {
							this.clearValue();
						}
					}}
				>
					<Field
						classNameList={['dynamic-field']}

						form={form}
						field={entity}
						value={this.getValue()}
						setValue={this.setValue}
						setStatus={this.setStatus}
						getStatus={this.getStatus}
					/>
				</div>
			)
		}

		return (
			<Field
				classNameList={['dynamic-field']}

				form={form}
				field={entity}
				value={this.getValue()}
				setValue={this.setValue}
				setStatus={this.setStatus}
				getStatus={this.getStatus}
			/>
		);
	}

	// return (
	// 	<div className="crow-field-container"
	// 		key={`crow-form-field-${entity.name}`}
	// 	>
	// 		<div className={`crow-field ${entity.fieldType.replace(/\./g, '-')}`}>

	// 			<div className="crow-field-label">
	// 				<label>{entity.attr.label || entity.name}</label>
	// 			</div>

	// 			{fieldElm}
	// 		</div>
	// 	</div>
	// );
}

export default CrowFormEntity;